import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import { VariantProps, cva } from "class-variance-authority";
import { ComponentPropsWithoutRef, createElement } from "react";

export type Spacing =
  | "none"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

export type Center = "none" | "horizontal" | "vertical" | "both";

export type ComponentChoice =
  | ({
      as?: "div";
    } & ComponentPropsWithoutRef<"div">)
  | ({
      as?: "span";
    } & ComponentPropsWithoutRef<"span">)
  | ({
      as?: "ol";
    } & ComponentPropsWithoutRef<"ol">)
  | ({
      as?: "ul";
    } & ComponentPropsWithoutRef<"ul">);

export default function Stack({
  as = "div",
  space = "none",
  center = "none",
  className,
  ...props
}: ComponentChoice & VariantProps<typeof StackCVA>) {
  return createElement(as, {
    className: twMerge(StackCVA({ space, center }), className),
    ...props,
  });
}

const spacingMap: Record<Spacing, string> = {
  none: "",
  xsmall: "tw-gap-y-1",
  small: "tw-gap-y-2",
  medium: "tw-gap-y-4",
  large: "tw-gap-y-8",
  xlarge: "tw-gap-y-16",
  xxlarge: "tw-gap-y-24",
};

const centerMap: Record<Center, string> = {
  none: "",
  horizontal: "tw-items-center",
  vertical: "tw-justify-center",
  both: "tw-items-center tw-justify-center",
};

const StackCVA = cva(["tw-flex tw-flex-col"], {
  variants: {
    space: spacingMap,
    center: centerMap,
  },
});
