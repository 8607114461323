"use client";

import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import cx from "classnames";
import * as React from "react";

import { HeaderStatusUpdate } from "./HeaderStatusUpdate";

export function StatusUpdate({
  value,
}: {
  value: { type: HeaderStatusUpdate["type"]; text: string | React.ReactNode };
}) {
  return (
    <div className="tw-bg-gray-100 tw-w-full">
      <div
        className={twMerge(
          cx("tw-w-full tw-text-base tw-text-center tw-py-2 tw-shadow-lg", {
            "tw-bg-red-500 tw-text-black": value.type === "error",
            "tw-bg-yellow-400 tw-text-black": value.type === "warning",
            "tw-bg-green-400 tw-text-black": value.type === "success",
            "tw-bg-blue-400 tw-text-black": value.type === "info",
          })
        )}
      >
        <div className="tw-container tw-mx-auto tw-px-4">{value.text}</div>
      </div>
    </div>
  );
}
