"use client";

import { canUseDOM } from "exenv";
import * as React from "react";

import { LoaderInline } from "./Loader";
import { FadeIn } from "./animation/FadeIn";
import useIsBrowser from "./useIsBrowser";

type BrowserOnlyProps = {
  children: React.ReactNode;
  serverRender?: React.ReactNode;
  loadingText?: string;
  className?: string;
};

export function assertIsRunningOnBrowser() {
  if (!canUseDOM) {
    throw new Error("Not running on the browser");
  }
}

export function BrowserOnly({
  children,
  serverRender,
  loadingText,
}: BrowserOnlyProps) {
  const isBrowser = useIsBrowser();

  if (isBrowser) {
    return <>{children}</>;
  } else {
    if (serverRender !== undefined) {
      return <>{serverRender}</>;
    } else {
      return <LoaderInline text={loadingText || ""} />;
    }
  }
}

export function BrowserOnlyFadeIn({
  children,
  className,
  ...props
}: BrowserOnlyProps) {
  return (
    <BrowserOnly {...props}>
      <FadeIn className={className}>{children}</FadeIn>
    </BrowserOnly>
  );
}

export default BrowserOnly;
