"use client";

import { useContext } from "react";

import { Context } from "./browserContext";

// packages/docusaurus/src/client/exports/useIsBrowser.ts
export default function useIsBrowser(): boolean {
  return useContext(Context);
}
