import { XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { MailIcon } from "@heroicons/react/solid";
import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import {
  Navbar,
  NavbarData,
} from "@mpay/web-tickets/src/components/common/layout/header/HeaderNav";
import { cloudinaryImageUrl } from "@mpay/web-tickets/src/utilities/cloudinary/utilities";
import { isWebsiteInIframe } from "@mpay/web-tickets/src/utilities/iframe";
import * as React from "react";

import { UserProfileDesktop } from "./HeaderUserProfile";

export function Header({
  client,
}: {
  client: {
    name: string;
    vanityUrl: string;
    logo: string;
    email: string;
  };
}) {
  const baseUrl = `/s/${client.vanityUrl}`;
  const imgUrl = cloudinaryImageUrl(client.logo, {
    height: 96,
    crop: "thumb",
    flags: "progressive:semi",
  });

  const theLinks: NavbarData = {
    logo: (
      <div className="tw-flex tw-items-center">
        <NextLink
          href={baseUrl}
          className="tw-flex tw-items-center tw-space-x-4"
          aria-label={client.name}
        >
          {client.logo && (
            <img
              src={imgUrl}
              alt={`${client.name} logo`}
              style={{
                height: "40px",
                maxWidth: "192px",
                borderRadius: "0.25rem",
              }}
            />
          )}
          <div>{client.name}</div>
        </NextLink>
      </div>
    ),
    dropdown: {
      open: (
        <span className="tw-flex tw-items-center tw-text-gray-700">
          Support <ChevronDownIcon className="tw-ml-2 tw-h-6 tw-w-6" />
        </span>
      ),
      closed: (
        <span className="tw-flex tw-items-center tw-text-gray-700">
          Support <XIcon className="tw-ml-2 tw-h-6 tw-w-6" />
        </span>
      ),
      links: [
        {
          type: "link",
          href: `mailto:help@matterpay.com`,
          children: (
            <>
              <div className="tw-uppercase tw-text-xs tw-text-gray-700">
                Technical support
              </div>
              <div className="tw-flex tw-items-center tw-text-blue-500">
                <MailIcon className="tw-w-5 tw-h-5 -tw-mb-0.5 tw-mr-2" />{" "}
                help@matterpay.com
              </div>
            </>
          ),
        },
        { type: "divider" },
        {
          type: "link",
          href: `${baseUrl}/privacy-policy`,
          children: "Privacy Policy",
        },
        {
          type: "link",
          href: `${baseUrl}/terms`,
          children: "Terms and Conditions",
        },
        { type: "divider" },
        {
          type: "link",
          href: `/login`,
          children: "Host Login",
        },
        {
          type: "externallink",
          href: `https://mpay.io`,
          children: "Sell tickets for your event",
        },
      ],
    },
    desktop: {
      links: [
        { type: "component", children: <UserProfileDesktop /> },
        { type: "link", href: `${baseUrl}`, children: "Our events" },
      ],
    },
  };

  if (client.email) {
    theLinks.dropdown?.links.unshift({
      type: "link",
      href: `mailto:${client.email}`,
      children: (
        <>
          <div className="tw-uppercase tw-text-xs tw-text-gray-700">
            Event support
          </div>
          <div className="tw-flex tw-items-center tw-text-blue-500">
            <MailIcon className="tw-w-5 tw-h-5 -tw-mb-0.5 tw-mr-2" />{" "}
            {client.email.length > 24 ? "Contact Us" : client.email}
          </div>
        </>
      ),
    });
  }

  // If the pages are rendered in an iFrame then don't display the header
  if (isWebsiteInIframe()) {
    return null;
  }

  return <Navbar data={theLinks} />;
}
