"use client";

import { UserIcon } from "@heroicons/react/solid";
import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import { getUserOrLoading } from "@mpay/web-tickets/src/utilities/user/userContext";

export function UserProfileDesktop() {
  const [user, loading] = getUserOrLoading();

  if (loading) {
    return null;
  }

  if (user.type === "client") {
    return (
      <NextLink href="/host/overview">
        <UserIcon className="tw-inline-block tw-w-5 tw-h-5 tw-mr-1" />{" "}
        {user.username}
      </NextLink>
    );
  }

  if (user.type === "admin") {
    return (
      <NextLink href="/admin/hosts">
        <UserIcon className="tw-inline-block tw-w-5 tw-h-5 tw-mr-1" />{" "}
        {user.username}
      </NextLink>
    );
  }

  return null;
}

export function UserProfileMobile() {
  const [user, loading] = getUserOrLoading();

  if (loading) {
    return null;
  }

  if (user.type === "client") {
    return (
      <NextLink href="/host/overview">
        <UserIcon className="tw-inline-block tw-w-5 tw-h-5 tw-mr-1" />{" "}
        {user.username}
      </NextLink>
    );
  }

  if (user.type === "admin") {
    return (
      <NextLink href="/admin/hosts">
        <UserIcon className="tw-inline-block tw-w-5 tw-h-5 tw-mr-1" />{" "}
        {user.username}
      </NextLink>
    );
  }

  return null;
}
