"use client";

import Divider from "@mpay/web-tickets/src/components/common/Divider";
import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import Spacer from "@mpay/web-tickets/src/components/common/Spacer";
import NotMobile from "@mpay/web-tickets/src/components/common/helpers/mobile/NotMobile";
import { HeaderTag } from "@mpay/web-tickets/src/components/common/typography/headers";
import { isWebsiteInIframe } from "@mpay/web-tickets/src/utilities/iframe";
import { usePathname } from "next/navigation";
import * as React from "react";

import { HeaderClient } from "./HeaderClient";

function Footer_({ client }: { client: HeaderClient }) {
  const baseHeaderLevel = 2;

  return (
    <div className="tw-bg-gray-100 tw-mt-8 tw-border-t tw-border-gray-300">
      <HeaderTag level={baseHeaderLevel} className="tw-sr-only">
        Footer
      </HeaderTag>
      <div className="tw-container tw-mx-auto tw-px-16 tw-space-y-16 tw-py-24">
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-space-y-12 md:tw-space-y-0">
          <section>
            <HeaderTag
              level={baseHeaderLevel + 1}
              className="tw-font-bold tw-mb-4"
            >
              Event Enquiries
            </HeaderTag>
            {client.email && client.email != "-" ? (
              <p>
                Email us at{" "}
                <a className="tw-text-blue-600" href={`mailto:${client.email}`}>
                  {client.email}
                </a>{" "}
                if you have any questions about our events.
              </p>
            ) : (
              <p>Get in touch if you have any questions.</p>
            )}
          </section>
          <div />
          <section>
            <HeaderTag
              level={baseHeaderLevel + 1}
              className="tw-font-bold tw-mb-4"
            >
              Terms
            </HeaderTag>
            <div>
              <div>
                <NextLink
                  applyLinkStyle
                  className="tw-text-blue-600"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </NextLink>
              </div>
              <div>
                <NextLink
                  applyLinkStyle
                  className="tw-text-blue-600"
                  href="/terms"
                >
                  Terms &amp; Conditions
                </NextLink>
              </div>
              <Spacer />
              <HeaderTag
                level={baseHeaderLevel + 1}
                className="tw-font-bold tw-mb-4"
              >
                Technical support
              </HeaderTag>
              <div>
                <a
                  href="mailto:help@matterpay.com"
                  className="tw-flex tw-items-center tw-space-x-2 tw-text-blue-600"
                >
                  <EmailSVG />
                  <div>help@matterpay.com</div>
                </a>
              </div>
            </div>
          </section>
        </div>
        <Divider />
        <div className="tw-text-center tw-text-sm">
          Ticketing by{" "}
          <a rel="noopener" target="_blank" href="https://matterpay.com">
            MatterPay
          </a>
          .
        </div>
      </div>
    </div>
  );
}

export function EmailSVG() {
  return (
    <div className="tw-flex">
      <svg
        className="tw-w-4 tw-h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    </div>
  );
}

export function Footer({ client }: { client: HeaderClient }) {
  // If the pages are rendered in an iFrame then don't display the header
  if (isWebsiteInIframe()) {
    return (
      <NotMobile>
        <div className="tw-w-full tw-h-8"></div>
      </NotMobile>
    );
  }

  const pathname = usePathname()!;
  const path = pathname.split("/");
  const showFooter = ["privacy-policy", "terms"];
  // typical path /s/client_name/page
  // If root i.e. /s/client_name/ show the footer always
  // If actual page then check against pages to show footer on
  if (!path[3] || (!!path[3] && showFooter.includes(path[3]))) {
    return <Footer_ client={client} />;
  } else {
    return (
      <NotMobile>
        <Footer_ client={client} />
      </NotMobile>
    );
  }
}
