import * as React from "react";

export function FadeIn(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <>
      <style>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .fadeIn {
          animation-name: fadeIn;
          animation-duration: 0.6s;
          animation-delay: 0.1s;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          opacity: 1e-10;
        }
      `}</style>
      <div className="fadeIn" {...props} />
    </>
  );
}

export function LongerFadeIn(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <>
      <style>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .longerFadeIn {
          animation-name: fadeIn;
          animation-duration: 1s;
          animation-delay: 1s;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          opacity: 1e-10;
        }
      `}</style>
      <div className="longerFadeIn" {...props} />
    </>
  );
}
