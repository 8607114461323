import * as React from "react";

const dividerColor = "rgba(0,0,0,0.15)";
export function Divider({ ...props }) {
  return (
    <hr style={{ height: "1px", backgroundColor: dividerColor }} {...props} />
  );
}

export function SimpleDivider(props: React.HTMLAttributes<HTMLDivElement>) {
  return <hr className="tw-border-gray-400 tw-mx-10" {...props} />;
}

export function SimpleHorizontalDivider(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  return (
    <div className="tw-border-l tw-border-gray-400 tw-w-px tw-h-6" {...props} />
  );
}

export function DividerText({ text }: { text: string }) {
  return (
    <div className="tw-flex">
      <div className="tw-flex-grow tw-flex tw-items-center">
        <div
          className="tw-mt-px tw-border-b tw-h-px tw-w-full"
          style={{ borderColor: dividerColor }}
        />
      </div>
      <div className="tw-mx-6">{text}</div>
      <div className="tw-flex-grow tw-flex tw-items-center">
        <div
          className="tw-mt-px tw-border-b tw-h-px tw-w-full"
          style={{ borderColor: dividerColor }}
        />
      </div>
    </div>
  );
}

export default Divider;
