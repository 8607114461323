"use client";

import { Container } from "@mpay/web-tickets/src/components/common/Container";
import {
  CommonError,
  ErrorBoundary,
} from "@mpay/web-tickets/src/components/common/ErrorBoundary";
import { FullPageLoader } from "@mpay/web-tickets/src/components/common/Loader";
import Stack from "@mpay/web-tickets/src/components/common/layout/Stack";
import { cloudinaryImageUrl } from "@mpay/web-tickets/src/utilities/cloudinary/utilities";
import { makeMetaFriendly } from "@mpay/web-tickets/src/utilities/matterpay";
import { SkipNavContent } from "@reach/skip-nav";
import Head from "next/head";
import * as React from "react";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { HeaderClient } from "./HeaderClient";
import { HeaderStatusUpdate } from "./HeaderStatusUpdate";
import { StatusUpdate } from "./StatusUpdate";

function BaseInner({
  children,
  header,
  footer,
  errorFallbackSuggestions,
}: {
  children: React.ReactNode;
  header: React.ReactNode;
  footer: React.ReactNode;
  errorFallbackSuggestions: React.ReactNode;
}) {
  return (
    <>
      <div className="background-tiles tw-flex tw-flex-col">
        <section>{header}</section>
        <div className="tw-flex-grow">
          <SkipNavContent />
          <ErrorBoundary
            fallback={
              <ErrorFallback
                errorFallbackSuggestions={errorFallbackSuggestions}
              />
            }
          >
            {children}
          </ErrorBoundary>
        </div>
        <footer>{footer}</footer>
      </div>
    </>
  );
}

function ErrorFallback({
  code,
  error,
  errorFallbackSuggestions,
}: {
  code?: string;
  error?: unknown;
  errorFallbackSuggestions: React.ReactNode;
}) {
  return (
    <Container>
      <Stack space="medium">
        <ErrorBoundary>
          <CommonError code={code} error={error} />
        </ErrorBoundary>
        <ErrorBoundary fallback={<></>}>
          {errorFallbackSuggestions}
        </ErrorBoundary>
      </Stack>
    </Container>
  );
}

function HeaderWithData({
  client,
  statusUpdates,
}: {
  client: HeaderClient;
  statusUpdates: Array<HeaderStatusUpdate>;
}) {
  const title = `${client.name} - MatterPay`;
  const description = `Events held by ${client.name} - with MatterPay`;
  const logo = cloudinaryImageUrl(client.logo, {
    width: 256,
    height: 256,
    crop: "thumb",
    flags: "progressive:semi",
  });

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={makeMetaFriendly(description, 300)} />
        <meta property="og:title" content={makeMetaFriendly(title, 50)} />
        <meta
          property="og:description"
          content={makeMetaFriendly(description, 300)}
        />
        <meta property="og:image" content={logo} />
        <link rel="shortcut icon" type="image/jpg" href={logo} />
      </Head>
      <Header client={client} />
      {statusUpdates
        .filter((statusUpdate) => statusUpdate.text?.length > 0)
        .filter((statusUpdate) => statusUpdate.type?.length > 0)
        .map((statusUpdate, i) => (
          <StatusUpdate key={i} value={statusUpdate} />
        ))}
    </>
  );
}

export function Base({
  client,
  children,
  statusUpdates,
  errorFallbackSuggestions,
}: {
  children: React.ReactNode;
  client: {
    id: string;
    name: string;
    logo: string;
    vanityUrl: string;
    email: string;
  };
  statusUpdates: Array<HeaderStatusUpdate>;
  errorFallbackSuggestions: React.ReactNode;
}) {
  return (
    <BaseInner
      header={
        <>
          <HeaderWithData client={client} statusUpdates={statusUpdates} />
          {/* <BasketDebug /> */}
        </>
      }
      footer={<Footer client={client} />}
      errorFallbackSuggestions={errorFallbackSuggestions}
    >
      <React.Suspense fallback={<FullPageLoader />}>{children}</React.Suspense>
    </BaseInner>
  );
}

export default Base;
