"use client";

import BrowserOnly from "@mpay/web-tickets/src/components/common/BrowserOnly";
import dynamic from "next/dynamic";
import * as React from "react";
import { useInterval } from "react-use";

import { useIdempotencyKeyFromCookieInterval } from "./_lib/useIdempotencyKeyFromCookieInterval";

const PeriodicallyCheckBasketCore = dynamic(
  () => import("./PeriodicallyCheckBasketCore"),
  { loading: () => <></>, ssr: false }
);

export function PeriodicallyCheckBasket() {
  const idempotencyKey = useIdempotencyKeyFromCookieInterval();
  const [now, setNow] = React.useState(new Date());
  useInterval(() => {
    setNow(new Date());
  }, 250);

  if (!idempotencyKey) {
    return null;
  } else {
    return (
      <BrowserOnly serverRender={null}>
        <PeriodicallyCheckBasketCore />
      </BrowserOnly>
    );
  }
}
