"use client";

import React, { type ReactNode } from "react";

export const Context = React.createContext<boolean>(false);

const emptySubscribe = () => () => {};

// Inspired by `packages/docusaurus/src/client/browserContext.tsx`
export function BrowserContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  // https://tkdodo.eu/blog/avoiding-hydration-mismatches-with-use-sync-external-store
  const isBrowser = React.useSyncExternalStore(
    emptySubscribe,
    () => true,
    () => false
  );
  return <Context.Provider value={isBrowser}>{children}</Context.Provider>;
}
