"use client";

import BrowserOnly from "@mpay/web-tickets/src/components/common/BrowserOnly";
import dynamic from "next/dynamic";

import { DataDogSettings } from "./DatadogRum.types";

const DatadogRumClientInner = dynamic(() => import("./DatadogRum.browser"), {
  ssr: false,
  loading: () => null,
});

export function DatadogRumClient({ settings }: { settings: DataDogSettings }) {
  return (
    <BrowserOnly serverRender={null}>
      <DatadogRumClientInner settings={settings} />
    </BrowserOnly>
  );
}
